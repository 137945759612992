<template>
	<div class="container">
		<div class="model" v-if="confirmModal" @click.stop="cancelConfirmModal()"></div>
		<div class="confirm-wrap flex-column align-center" v-if="confirmModal">
			<div class="confirm-title">请填入本次发新兑换的推广卡</div>
			<div class="con-con-wrap flex-row align-center">
				<input type="number" v-model="dnum" class="con-input" placeholder="请输入兑换数量">
				<div class="con-unit">张</div>
			</div>
			<div class="con-tips1">注意：每次发新只有一次兑换机会， </div>
			<div class="con-tips2">且提交后无法更改</div>
			<div class="con-btn" @click.stop="confirm()">确认</div>
		</div>
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">我的推广卡</div>
			<div class="zd-btn" @click.stop="navLog()">记录</div>
		</div>
		<div class="wrap">
			<div class="img-wrap flex-row align-center justify-center">
				<div class="num">{{num}}</div>
				<div class="unit">张</div>
			</div>
			<div class="tips">有效期至：2022年12月31日</div>
			<div v-if="canSubmit ==1" class="btn" @click.stop="exchange()">兑换</div>
			<div v-if="canSubmit ==0" class="btn-false">已兑换</div>
		</div>
	</div>
</template>

<script>
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import config from '../config/config.js';
	import util from '../config/util.js';

	export default {
		data() {
			return {
				num: '',
				confirmModal: 0,
				dnum: '',
				canSubmit: -1
			}
		},
		methods: {
			exchange() {
				const that = this;
				if (that.num <= 0) {
					Toast('请先销毁获取推广卡配额')
					return;
				}
				this.confirmModal = 1;
			},
			cancelConfirmModal() {
				this.confirmModal = 0
			},
			confirm() {
				const that = this;
				if (that.dnum <= 0) {
					Toast('请输入兑换数量')
					return;
				}
				if (that.dnum > that.num) {
					Toast('推广卡数量不足')
					return;
				}
				Toast.loading({
					message: '',
					forbidClick: true,
					duration: 0,
				});
				let params = new FormData()
				params.append('quota', that.dnum)
				this.axios.post(config.requestUrl + '/front/destRebate/submitApply', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.getPreBuyCount();
						that.confirmModal = 0;
						that.canSubmit = 0
						Toast('兑换成功');

					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getPreBuyCount() {
				const that = this;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/destRebate/getPreBuyCount', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.num = response.data.obj;
						console.log(response)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			navLog() {
				this.$router.push('/extensionlog')
			},
			checkCanSubmit() {
				const that = this;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/destRebate/checkCanSubmit', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.canSubmit = 1;
						console.log(response)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
							Toast(response.data.msg);
						} else {
							that.canSubmit = 0
						}
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			}
		},
		mounted() {
			this.getPreBuyCount();
			this.checkCanSubmit();
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		min-height: 100%;
		background: #327DFF;
	}

	.wrap {
		width: 680px;
		height: max-content;
		margin: 46px auto 0;
		padding: 155px 0;
		background: #FFFFFF;
		box-shadow: inset 0px 0px 87px 0px #FFFFFF;
		border-radius: 20px;
	}

	.img-wrap {
		width: 573px;
		height: 483px;
		margin: auto;
		background-image: url(../assets/destroy/bg.png);
		background-size: 573px 483px;
	}



	.bg {
		width: 680px;
		height: 746px;
	}

	.num {
		font-size: 150px;
		font-weight: 600;
		color: #ffffff;
		margin-bottom: 30px;
	}

	.unit {
		margin: 60px 0 30px 10px;
		font-size: 24px;
		font-weight: 400;
		color: #ffffff;
	}

	.tips {
		width: max-content;
		padding: 0 66px;
		height: 62px;
		margin: 16px auto;
		background: linear-gradient(to right, #ffffff, rgb(248, 246, 255), #fff);
		border-radius: 40px;
		font-size: 24px;
		font-weight: 400;
		color: #5A6BE7;
		text-align: center;
		line-height: 62px;
	}

	.zd-btn {
		width: 105px;
		height: 49px;
		background: rgba(255, 255, 255, 0.3);
		border-radius: 25px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 49px;
		text-align: center;
		position: absolute;
		top: 0;
		right: 30px;
		bottom: 0;
		margin: auto;
	}

	.btn {
		width: 330px;
		height: 80px;
		margin: 296px auto auto;
		background: #327DFF;
		border-radius: 40px;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		text-align: center;
	}

	.btn-false {
		width: 330px;
		height: 80px;
		margin: 296px auto auto;
		background: #cccccc;
		border-radius: 40px;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		text-align: center;
	}

	.confirm-title {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		margin-bottom: 38px;
	}

	.confirm-wrap {
		width: 630px;
		height: max-content;
		background: #FFFFFF;
		border-radius: 20px;
		padding: 35px 0 0;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		box-sizing: border-box;
		z-index: 1111;
	}

	.con-con-wrap {
		width: 480px;
		height: 70px;
		padding: 0 42px;
		background: rgba(50, 125, 255, 0.1300);
		border-radius: 10px;
		box-sizing: border-box;
	}

	.con-input {
		width: 400px;
		font-size: 26px;
		color: #000000;
		border: none;
		text-align: center;
		background: initial;
		padding: 0;
		margin: 0;
	}

	.con-unit {
		font-size: 30px;
		font-weight: 600;
		color: #327DFF;
	}

	.con-tips1 {
		margin-top: 21px;
		font-size: 24px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5000);
	}

	.con-tips2 {
		font-size: 24px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5000);
	}

	.con-btn {
		width: 630px;
		height: 88px;
		margin-top: 40px;
		background: #FFFFFF;
		box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.1000), 0px -1px 0px 0px rgba(0, 0, 0, 0.1000);
		border-radius: 0px 0px 20px 20px;
		text-align: center;
		line-height: 88px;
	}
</style>
